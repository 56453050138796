import React from "react";
import { createBrowserRouter } from "react-router-dom";

import AppLayout from "./containers/AppLayout";
import { Palette, Home, Default, News, NewsDetail, NoMatch } from "./components/index.js";

import ForLease from "./containers/ForLease";

import { getStrapiContent, removeCommentAttributes } from "./Functions";

const myHeaders = new Headers();
myHeaders.append("Authorization", `Bearer ${process.env.REACT_APP_STRAPI_ACCESS_TOKEN}`);
myHeaders.append("Accept", "*/*");

const requestOptions = {
  method: "GET",
  headers: myHeaders,
  // redirect: "follow",
};
const ENDPOINTS = [
  "gallery",
  "faq",
  "home",
  "visit-us",
  "living-here",
  "retail-leasing",
  "for-lease",
  "builder",
  "community-clubhouse",
  "news-page",
  "showhomes",
];
const [
  gallery,
  faq,
  home,
  visitUs,
  livingHere,
  retailLeasing,
  forLease,
  builder,
  communityClubhouse,
  newsPage,
  ShowHomeData,
] = await Promise.all(
  ENDPOINTS.map(endpoint => {
    return fetch(`${process.env.REACT_APP_CMS_URL}/api/${endpoint}?populate=deep`, requestOptions)
      .then(response => {
        return response.json();
      })
      .then(data => {
        const dataTest = removeCommentAttributes(
          JSON.stringify(data.data.attributes || data.data || data)
        );
        let modifiedData = JSON.parse(dataTest);
        modifiedData = JSON.parse(
          dataTest.replace(/"\/uploads\/+/g, `"${process.env.REACT_APP_CMS_URL}/uploads/`)
        );
        return modifiedData;
      })
      .catch(error => {
        console.log("error", error);
      });
  })
);

const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <AppLayout />,
      children: [
        {
          path: "/",
          element: <Home data={home} />,
          index: true,
          id: "root",
          // loader: () => home,
        },
        {
          path: "/visit-us",
          element: (
            <Default
              pageTitle="Visit Us"
              pageId="visit-us"
              data={visitUs}
              showHomeData={ShowHomeData}
            />
          ),
          id: "visit-us",
          // loader: () => visitUs,
        },
        {
          path: "/living-here",
          element: <Default pageTitle="Living Here" pageId="living-here" data={livingHere} />,
          id: "living-here",
          // loader: () => livingHere,
        },
        {
          path: "/retail",
          element: <Default pageTitle="Retail" pageId="retail" data={retailLeasing} />,
          id: "retail",
          // loader: () => retailLeasing,
        },
        {
          path: "/for-lease",
          element: <ForLease pageTitle="For Lease" pageId="for-lease" data={forLease} />,
          id: "for-lease",
          // loader: () => forLease,
        },
        {
          path: "/builders",
          element: <Default pageTitle="For Lease" pageId="builders" data={builder} />,
          id: "builders",
          // loader: () => builder,
        },
        {
          path: "/community-clubhouse",
          element: (
            <Default
              pageTitle="community-clubhouse"
              pageId="community-clubhouse"
              data={communityClubhouse}
            />
          ),
          id: "community-clubhouse",
          // loader: () => communityClubhouse,
        },
        {
          path: "/gallery",
          element: <Default pageTitle="gallery" pageId="gallery" data={gallery} />,
          id: "gallery",
          // loader: () => gallery,
        },
        {
          path: "/faq",
          element: <Default pageTitle="faq" pageId="faq" data={faq} />,
          id: "faq",
          // loader: () => faq,
        },
        {
          path: "/palette",
          element: <Palette pageTitle="palette" pageId="palette" />,
          id: "palette",
        },
        {
          path: "/news",

          element: <News pageTitle="news" pageId="news" />,

          children: [
            {
              path: "/news",
              element: <Default pageTitle="news" pageId="news" data={newsPage} />,
              id: "news",
              // loader: () => getStrapiContent("/api/news-page/"),
            },
            {
              path: "/news/:id",
              element: (
                <NewsDetail
                  requestOptions={requestOptions}
                  removeCommentAttributes={removeCommentAttributes}
                  data={
                    newsPage?.PageContent?.filter((item, index) => {
                      return item.__component === "content.news-section";
                    })[0]?.NewsItems
                  }
                />
              ),
              // element: <NewsDetail setNewsId={setNewsId} newsId={newsId} newsDetail={newsDetail} />,
            },
          ],
        },
        {
          path: "*",
          element: <NoMatch />,
          id: "No Match",
        },
      ],
    },
  ],
  {
    basename: "/",
  }
);

export default router;
